import Header from '../../../Header.vue'
import Footer from '../../../Footer.vue'
import UserLeft from '../../PersonUserLeft.vue'
import CheckInService from '../../../../api/CheckInService.js'
import axios from 'axios'

export default{
	name:'ManuscriptDetail',
	data(){
		return{
			manuscriptInfo:''
		}
	},
	methods:{
		GetManuscriptDetail(){ //获取稿件详情
			var that = this
			CheckInService.GetManuscriptInfo(that.$route.query.id).then(res=>{
				console.log(res)
				if(res.Tag == 1){
					that.manuscriptInfo = res.Result
				}
			})
		},
		downLoadFile() { //下载文件
			var that = this
			var FilePath = that.manuscriptInfo.FileModel.ResPath
			var FileName = that.manuscriptInfo.FileModel.ResName.split('.')[0]
			if (!FilePath) {
				return;
			}
			axios
				.get(FilePath, {
					responseType: 'blob'
				})
				.then(response => {
					const url = window.URL.createObjectURL(new Blob([response.data]))
					const link = document.createElement('a')
					link.href = url
					let fname = FileName + '.' +FilePath.split('.')[1]					
					link.setAttribute('download', fname)
					document.body.appendChild(link)
					link.click()
				})
		},
		toElementDetail(id){ //词条详情
			window.open(this.$WebUrl+'ElementDetail?id='+id)
		}
	},
	created(){
		this.GetManuscriptDetail()
	},
	components:{
		'Header': Header,
		'Footer':Footer,
		'UserLeft':UserLeft
	}
}